import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "react-input-range/lib/css/index.css"
import Masonry from 'react-masonry-component'
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import { getUser, isLoggedIn } from "../../../services/auth"
import QuickViewModalData from "../../ContentBuilder/Modal/QuickViewModalData"
import { connect } from "react-redux"
import { doFilter, filterVariant } from "../../../services/filter"
import config from "../../../config/config"

const AllList = ({ activeFilters, currentBoards, sort, currentSearch }) => {
  return <AllItems activeFilters={activeFilters} currentBoards={currentBoards} sort={sort} currentSearch={currentSearch} />
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active,
  currentBoards: state.board.all,
  sort: state.sort.current,
  currentSearch: state.search.current
})

export default connect(mapStateToProps, null)(AllList)

function AllItems({ activeFilters, currentBoards, sort, currentSearch }) {
  const loadPage = () => {
    setPageLimit(pageLimit + 12)
    if (pageLimit + 12 >= tiles.length) {
      setHasMore(false)
    }
  }
  const allItems = useStaticQuery(graphql`
    query MyQuery {
        silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
            UndigitalGlobalConfig {
                hidePricingGlobally
                hideStyleSection
                hideHomeDesignSection
                hideFacadesSection    
                hideFinishesFixturesSection
                hideCabinetrySection
                hideFurnitureSection
          }
        }
      allSilverStripeDataObject(filter: {link: {eq: null}, className: {in: [
        "Undigital__Objects__HomeDesign", 
        "Undigital__Objects__Cabinetry", 
        "Undigital__Objects__Furniture", 
        "Undigital__Objects__Selection", 
        "Undigital__Objects__Facade", 
        "Undigital__Objects__FinishesFixtures"]}}) {
        nodes {
          UndigitalHomeDesign {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            imagePosition
            description
            Storeys {
              UndigitalStoreys {
                name
              }
            }
            FeaturedFloorPlan {
              UndigitalFloorplan {
                name
                objectID
                bath
                bed
                garage
                living                
                firstFloorImagePath
                groundFloorImagePath
              }
            }
            Floorplans {
              UndigitalFloorplan {
                objectID
                name
                homeArea
                homeSize
                rrpPrice            
                lotWidth
                lotLength
                status
                numOfLayout
                bed
                bath
                garage
                living
                firstFloorImagePath
                groundFloorImagePath
                GroundFloorRoomsAmenities {
                  UndigitalRoomAmenity {
                    name
                    objectID
                  }
                }
                Level1RoomsAmenities {
                  UndigitalRoomAmenity {
                    name
                    objectID
                  }
                }
              }
            }
            urlSegment
            status
            imagePath
            homeTypeID
            storeysID
          }
          UndigitalCabinetry {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            urlSegment
            status            
            description
            Variations {
              UndigitalCabinetryVariation {
                featureImagePath
                imagePosition
                name
                objectID
                status
                rrPrice    
                Styles {
                  UndigitalProductStyle {
                    objectID
                    name
                  }
                }
              }
            }
            FeaturedVariation {
              UndigitalCabinetryVariation {
                name
                objectID
                rrPrice
                imagePosition
                featureImagePath                
                Styles {
                  UndigitalProductStyle {
                    name
                  }
                }
              }
            }
          }
          UndigitalFurniture {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            urlSegment
            about
            status            
            Variations {
              UndigitalFurnitureVariation {
                status
                name
                objectID
                rrp
                productImagePath
                imagePosition
                styleID
                colourID
              }
            }
            FeaturedVariation {
              UndigitalFurnitureVariation {
                name
                objectID
                productImagePath
                imagePosition
                rrp          
                Style {
                  UndigitalProductStyle {
                    name
                  }
                }
              }
            }
            brandID
          }
          UndigitalSelection {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            urlSegment
            status
            imagePosition
            imagePath
            about
            Items {
              UndigitalSelectionItem {
                name
                objectID
                status
                productImagePath
              }
            }
            Style {
              UndigitalProductStyle {
                name
              }
            }
            styleID
          }
          UndigitalFacade {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            urlSegment
            about
            status
            Variations {
              UndigitalFacadeVariation {
                name
                objectID
                featureImagePath
                imagePosition
                status
                rrPrice
                Storeys {
                  UndigitalStoreys {
                    objectID
                    name
                  }
                }
              }
            }
            FeaturedVariation {
              UndigitalFacadeVariation {                
                name
                objectID
                rrPrice
                featureImagePath
                imagePosition
                Storeys {
                  UndigitalStoreys {
                    objectID
                    name
                  }
                }
              }
            }
          }
          UndigitalFinishesFixtures {
            name
            objectID
            showTitleOnTile
            tileBlackTitle
            status
            urlSegment
            about            
            Variations {
              UndigitalFinishesFixturesVariation {
                name
                objectID
                featureProductImagePath
                imagePosition
                status
                rrp
                colourID
              }
            }
            FeaturedVariation {
              UndigitalFinishesFixturesVariation {
                name
                objectID
                rrp
                featureProductImagePath
                imagePosition
                colourID
              }
            }
            brandID
          }
        }
      }
    }    
  `)
    const host = config.host
    const [pageLimit, setPageLimit] = useState(15)
    const [hasMore, setHasMore] = useState(true)  
    const unfiltered_items = allItems.allSilverStripeDataObject.nodes
    const _HomeDesignList = unfiltered_items.filter(function(item) {
        return item.UndigitalHomeDesign != null
    })
    const _CabinetryList = unfiltered_items.filter(function(item) {
        return item.UndigitalCabinetry != null
    })
    const _FurnitureList = unfiltered_items.filter(function(item) {
        return item.UndigitalFurniture != null
    })
    const _SelectionList = unfiltered_items.filter(function(item) {
        return item.UndigitalSelection != null
    })
    const _FacadeList = unfiltered_items.filter(function(item) {
        return item.UndigitalFacade != null
    })
    const _FinishesFixturesList = unfiltered_items.filter(function(item) {
        return item.UndigitalFinishesFixtures != null
    })
    const globalConfig = allItems.silverStripeDataObject.UndigitalGlobalConfig
    const classes = ['grid-item', 'grid-item no-hover', 'grid-item large']
    const search = typeof window !== "undefined" ? window.location.search : "/"
    const params = new URLSearchParams(search)
    var keyword = params.get("keyword")
    var items = []
  if(_HomeDesignList.length > 0 && globalConfig.hideHomeDesignSection === 0){
    for(var a=1;a<_HomeDesignList.length;a++){
          let _item = _HomeDesignList[a].UndigitalHomeDesign
          let designName = _item.name
          if(_item.status !== 'Active') continue
          let image_path = null
          var FeaturedFloorPlan = _item.FeaturedFloorPlan
          if (FeaturedFloorPlan !== null) {
            if (FeaturedFloorPlan.UndigitalFloorplan.groundFloorImagePath) {
              image_path = FeaturedFloorPlan.UndigitalFloorplan.groundFloorImagePath
            } else if (FeaturedFloorPlan.UndigitalFloorplan.firstFloorImagePath) {
              image_path = _item.UndigitalFloorplan.firstFloorImagePath
            }
          }
          if(image_path === null){
              image_path = _item.imagePath
          }
          let floorplans_liked = 0
          if(_item.Floorplans){
              for (const floorPlan of _item.Floorplans) {
                  var cmsFloorPlan = floorPlan.UndigitalFloorplan
                  if (cmsFloorPlan.status !== 'Active') continue
                  var isSaved = false
                  for (const board of currentBoards) {
                      const board_items = JSON.parse(board.Items)
                      for (const key in board_items) {
                          if (key.includes(_item.className) && board_items[key].ID == _item.objectID && board_items[key].VariantID == cmsFloorPlan.objectID) {
                            isSaved = true
                          }
                      }                    
                  }
                  if(isSaved){
                      floorplans_liked += 1
                  }
                  var tile = {
                    _item: { ..._item },
                    ...cmsFloorPlan,
                    rrpPrice: parseFloat(Math.round(cmsFloorPlan.rrpPrice * 1000) / 1000).toFixed(2)
                  }          
                  tile.formattedPrice = "$" + tile.rrpPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  _item.formattedPrice = tile.formattedPrice
                  if(isSaved){
                      tile.isSaved = true
                      floorplans_liked += 1
                  }
                  tile.imagePosition = tile.imagePosition
                  tile.rrp = tile.formattedPrice
                  tile.likes = floorplans_liked
                  var isDoubleStorey = false
                  let match = true
                  var _storeys = ""
                  var storeysList = _item.Storeys
                  if (storeysList !== null) {
                    _storeys = storeysList.UndigitalStoreys.name
                    if (_storeys === 'Double Storey') {
                      isDoubleStorey = true
                    }
                  }
                  tile.isDoubleStorey = isDoubleStorey

                  if (keyword) {
                      keyword = keyword.toLowerCase()
                      let _designName = designName.toLowerCase()
                      _storeys = _storeys.toLowerCase()

                      let matched_title = _designName.search(keyword)
                      let matched_storeys = _storeys.search(keyword)
                      let matched_tileName = tile.name.search(keyword)

                      if (
                        matched_title === -1 &&
                        matched_storeys === -1 &&
                        matched_tileName === -1
                      ) {
                        match = false
                      }
                  }

                  tile.bed = []
                  tile.bath = []
                  tile.garage = []
                  tile.living = []
                  tile.roomAmenities = []
                  tile.roomAmenitiesObjs = []
                  var shortSize = ''                
                  if(cmsFloorPlan.homeSize || cmsFloorPlan.homeArea){
                      shortSize += '<strong>SIZE</strong>'
                      if(cmsFloorPlan.homeSize){
                          shortSize += ' / ' + cmsFloorPlan.homeSize + ' SQ'
                      }
                      if(cmsFloorPlan.homeArea){
                          shortSize += ' / ' + cmsFloorPlan.homeArea + ' M2'
                      }
                  }
                  tile.shortSize = shortSize
                  if (cmsFloorPlan.bed && !tile.bed.includes(cmsFloorPlan.bed)) tile.bed.push(cmsFloorPlan.bed)
                  if (cmsFloorPlan.bath && !tile.bath.includes(cmsFloorPlan.bath)) tile.bath.push(cmsFloorPlan.bath)
                  if (cmsFloorPlan.garage && !tile.garage.includes(cmsFloorPlan.garage)) tile.garage.push(cmsFloorPlan.garage)
                  if (cmsFloorPlan.living && !tile.living.includes(cmsFloorPlan.living)) tile.living.push(cmsFloorPlan.living)

                  if (cmsFloorPlan.GroundFloorRoomsAmenities) {
                    for (const ite of cmsFloorPlan.GroundFloorRoomsAmenities) {
                      const ra = ite.UndigitalRoomAmenity
                      if (!tile.roomAmenities.some(t => t === ra.objectID)) {
                        tile.roomAmenities.push(ra.objectID)
                        tile.roomAmenitiesObjs.push(ra)
                      }
                    }
                  }

                  if (cmsFloorPlan.Level1RoomsAmenities) {
                    for (const ite of cmsFloorPlan.Level1RoomsAmenities) {
                      const ra = ite.UndigitalRoomAmenity
                      if (!tile.roomAmenities.some(t => t === ra.objectID)) {
                        tile.roomAmenities.push(ra.objectID)
                        tile.roomAmenitiesObjs.push(ra)
                      }
                    }
                  }

                  if (_item.homeTypeID) tile.homeTypes = [_item.homeTypeID]
                  if (_item.storeysID) tile.storeys = [_item.storeysID]
                  tile.className = 'HomeDesign'          
                  tile.link = `/explore/home-designs/${_item.urlSegment}/?vid=${tile.objectID}`
                  tile.Image = cmsFloorPlan.groundFloorImagePath
                  tile.grid = classes[0]
                  if (match) {
                    items.push(tile)
                  }
              }
          }
      }
    }
    if(_CabinetryList.length > 0 && globalConfig.hideCabinetrySection === 0){
        for (var b=0; b<_CabinetryList.length;b++) {
            let _item = _CabinetryList[b].UndigitalCabinetry
            _item.about = _item.description
            if(_item.status !== 'Active') continue
            var name = _item.name
            let match = true
            if (keyword) {
              keyword = keyword.toLowerCase()
              let _name = name.toLowerCase()

              let matched_name = _name.search(keyword)

              if (matched_name === -1) {
                match = false
              }
            }
            var featuredVariation = _item.FeaturedVariation
            _item.variantTiles = _item.Variations ? _item.Variations.map(v => {
              return {
                ...v.UndigitalCabinetryVariation,
                featured: v.objectID == featuredVariation.UndigitalCabinetryVariation.objectID
              }
            }).filter(v => v.status === 'Active').sort((i1, i2) => {
              if (i1.objectID == featuredVariation.UndigitalCabinetryVariation.objectID) return -1
              if (i2.objectID == featuredVariation.UndigitalCabinetryVariation.objectID) return 1
              return 0
            }) : []
            var FeatureImage = null
            if (featuredVariation !== null && featuredVariation.UndigitalCabinetryVariation.featureImagePath) {
              FeatureImage = featuredVariation.UndigitalCabinetryVariation.featureImagePath
            }
            _item.Image = FeatureImage
            let rrp = 0
            if (featuredVariation !== null) {
              rrp = featuredVariation.UndigitalCabinetryVariation.rrPrice
              _item.imagePosition = featuredVariation.UndigitalCabinetryVariation.imagePosition
            }
            _item.featureRRPrice = rrp
            let itemPrice = parseFloat(
              Math.round(_item.featureRRPrice * 1000) / 1000
            ).toFixed(2)
            _item.formattedPrice =
              "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            _item.rrp = _item.formattedPrice
            _item.link = '/explore/cabinetry/' + _item.urlSegment
            _item.about = _item.description
            _item.className = 'Cabinetry'
            _item.grid = classes[0]
            if (_item.Variations) {
                _item.styleArray = []
                for (const i of _item.Variations) {
                  const variant = i.UndigitalCabinetryVariation
                  if (variant.Styles) {
                    for (const st of variant.Styles) {
                      const style = st.UndigitalProductStyle
                      if (!_item.styleArray.some(sa => sa === style.objectID)) _item.styleArray.push(style.objectID)
                    }
                  }
                }
            }

            if (match) {
              items.push(_item)
            }
        }
    }
    if(_FurnitureList.length > 0 && globalConfig.hideFurnitureSection === 0){
        for (var c=0; c<_FurnitureList.length;c++) {
            let _item = _FurnitureList[c].UndigitalFurniture
            if(_item.status !== 'Active') continue
            _item.link = '/explore/furniture/' + _item.urlSegment + '/'
            let stylesString = ""
            let rrp = 0
            if (_item.FeaturedVariation !== null) {
              var featuredVariationFurniture = _item.FeaturedVariation
              if (featuredVariationFurniture.UndigitalFurnitureVariation.Style !== null) {
                stylesString = featuredVariationFurniture.UndigitalFurnitureVariation.Style.UndigitalProductStyle.name
              }
              rrp = featuredVariationFurniture.UndigitalFurnitureVariation.rrp
              _item.imagePath = featuredVariationFurniture.UndigitalFurnitureVariation.productImagePath
              _item.Image = featuredVariationFurniture.UndigitalFurnitureVariation.productImagePath
              _item.imagePosition = featuredVariationFurniture.UndigitalFurnitureVariation.imagePosition
            }
            let itemPrice = parseFloat(
              Math.round(rrp * 1000) / 1000
            ).toFixed(2)
            _item.rrp = "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            _item.bottomPrice = "Price: " + _item.rrp
            _item.styles = stylesString
            _item.grid = classes[0]
            _item.className = 'Furniture'

            if (_item.brandID) _item.brands = [_item.brandID]
            if (_item.Variations) {
              for (const i of _item.Variations) {
                const variant = i.UndigitalFurnitureVariation
                if (variant.styleID) _item.styleArray = [variant.styleID]
                if (variant.colourID) _item.colorArray = [variant.colourID]
              }
            }
            var FeaturedVariation = _item.FeaturedVariation
            _item.variantTiles = _item.Variations ? _item.Variations.map(v => {
              return {
                ...v.UndigitalFurnitureVariation,
                featured: v.objectID == FeaturedVariation.UndigitalFurnitureVariation.objectID
              }
            }).filter(v => v.status === 'Active').sort((i1, i2) => {
              if (i1.objectID == FeaturedVariation.UndigitalFurnitureVariation.objectID) return -1
              if (i2.objectID == FeaturedVariation.UndigitalFurnitureVariation.objectID) return 1
              return 0
            }) : []

            let match = true
            if (keyword) {
              keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
              let keyword_arr = keyword.split(" ")
              let _name = _item.name.toLowerCase()
              if (!keyword_arr.some(v => _name.includes(v))) {
                match = false
              }
            }
            if (match) {
              items.push(_item)
            }
        }
    }
    if(_SelectionList.length > 0 && globalConfig.hideStyleSection === 0){
        for (var d=0; d<_SelectionList.length;d++) {
            let _item = _SelectionList[d].UndigitalSelection
            if(_item.status !== 'Active') continue
            let stylesString = ""
            _item.link = '/explore/style/' + _item.urlSegment + '/'
            if (_item.Style !== null) {
              stylesString = _item.Style.UndigitalProductStyle.name
            }
            _item.styles = stylesString
            _item.grid = classes[0]
            _item.className = 'Selection'

            if (_item.styleID) _item.styleArray = [_item.styleID]

            let match = true
            if (keyword) {
              keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
              let keyword_arr = keyword.split(" ")
              let _name = _item.name.toLowerCase()
              if (!keyword_arr.some(v => _name.includes(v))) {
                match = false
              }
            }
            _item.Image = _item.imagePath

            if (match) {
              items.push(_item)
            }
        }
    }
    if(_FacadeList.length > 0 && globalConfig.hideFacadesSection === 0){
        for (var e=0; e<_FacadeList.length;e++) {
            let _item = _FacadeList[e].UndigitalFacade
            if(_item.status !== 'Active') continue
            _item.link = '/explore/facades/' + _item.urlSegment + '/'
            let stylesString = ""
            let rrp = 0
            if (_item.FeaturedVariation !== null) {
              var featuredVariationFacade = _item.FeaturedVariation
              if (featuredVariationFacade.UndigitalFacadeVariation.Styles !== null) {
                const styles = featuredVariationFacade.UndigitalFacadeVariation.Styles
              }
              rrp = featuredVariationFacade.UndigitalFacadeVariation.rrPrice
              _item.imagePath = featuredVariationFacade.UndigitalFacadeVariation.featureImagePath
              _item.imagePosition = featuredVariationFacade.UndigitalFacadeVariation.imagePosition
            }

            var FeaturedVariation = _item.FeaturedVariation
            _item.variantTiles = _item.Variations ? _item.Variations.map(v => {
              return {
                ...v.UndigitalFacadeVariation,
                featured: v.objectID == FeaturedVariation.UndigitalFacadeVariation.objectID
              }
            }).filter(v => v.status === 'Active').sort((i1, i2) => {
              if (i1.objectID == FeaturedVariation.UndigitalFacadeVariation.objectID) return -1
              if (i2.objectID == FeaturedVariation.UndigitalFacadeVariation.objectID) return 1
              return 0
            }) : []

            let image_path = null
            if (FeaturedVariation && FeaturedVariation.UndigitalFacadeVariation.featureImagePath) {
              image_path = FeaturedVariation.UndigitalFacadeVariation.featureImagePath
            }
            _item.className = 'Facade'
            _item.Image = image_path

            let itemPrice = parseFloat(
              Math.round(rrp * 1000) / 1000
            ).toFixed(2)
            _item.rrp = "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            _item.bottomPrice = "Price: " + _item.rrp
            _item.styles = stylesString
            _item.grid = classes[0]

            if (_item.Variations) {
              for (const i of _item.Variations) {
                const variant = i.UndigitalFacadeVariation
                if (variant.Styles) {
                  _item.styleArray = []
                  for (const i of variant.Styles) {
                    const style = i.UndigitalProductStyle
                    if (!_item.styleArray.some(sa => sa === style.objectID)) _item.styleArray.push(style.objectID)
                  }
                }
                if (variant.storeysID) _item.storeys = [variant.storeysID]
              }
            }

            let match = true
            if (keyword) {
              keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
              let keyword_arr = keyword.split(" ")
              let _name = _item.name.toLowerCase()
              if (!keyword_arr.some(v => _name.includes(v))) {
                match = false
              }
            }
            if (match) {
              items.push(_item)
            }
        }
    }
    if(_FinishesFixturesList.length > 0 && globalConfig.hideFinishesFixturesSection === 0){
        for (var f=0; f<_FinishesFixturesList.length;f++) {
            let _item = _FinishesFixturesList[f].UndigitalFinishesFixtures
            if(_item.status !== 'Active') continue
            _item.link = '/explore/finishes-fixtures/' + _item.urlSegment + '/'
            let stylesString = ""
            let rrp = 0
            let image_path = null
            if (_item.FeaturedVariation !== null) {
              var featuredVariationFixture = _item.FeaturedVariation
              rrp = featuredVariationFixture.UndigitalFinishesFixturesVariation.rrp
              _item.imagePath = featuredVariationFixture.UndigitalFinishesFixturesVariation.featureProductImagePath
              _item.imagePosition = featuredVariationFixture.UndigitalFinishesFixturesVariation.imagePosition
              image_path = featuredVariationFixture.UndigitalFinishesFixturesVariation.featureProductImagePath
            }
            _item.Image = image_path
            let itemPrice = parseFloat(
              Math.round(rrp * 1000) / 1000
            ).toFixed(2)
            _item.rrp = "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            _item.bottomPrice = "Price: " + _item.rrp
            _item.styles = stylesString
            _item.grid = classes[0]
            _item.className = 'FinishesFixtures'

            if (_item.brandID) _item.brands = [_item.brandID]
            if (_item.Variations) {
              for (const i of _item.Variations) {
                const variant = i.UndigitalFinishesFixturesVariation
                if (variant.styleID) _item.styleArray = [variant.styleID]
                if (variant.colourID) _item.colorArray = [variant.colourID]
              }
            }
            var FeaturedVariation = _item.FeaturedVariation
            _item.variantTiles = _item.Variations ? _item.Variations.map(v => {
              return {
                ...v.UndigitalFinishesFixturesVariation,
                featured: v.objectID == FeaturedVariation.UndigitalFinishesFixturesVariation.objectID
              }
            }).filter(v => v.status === 'Active').sort((i1, i2) => {
              if (i1.objectID == FeaturedVariation.UndigitalFinishesFixturesVariation.objectID) return -1
              if (i2.objectID == FeaturedVariation.UndigitalFinishesFixturesVariation.objectID) return 1
              return 0
            }) : []
            let match = true
            if (keyword) {
              keyword = keyword.toLowerCase().trim().replace(/  +/g, ' ')
              let keyword_arr = keyword.split(" ")
              let _name = _item.name.toLowerCase()
              if (!keyword_arr.some(v => _name.includes(v))) {
                match = false
              }
            }
            if (match) {
              items.push(_item)
            }
        }
    } 
  // do filter
  if (activeFilters.length) items = doFilter(activeFilters, items)

  // search
  if (currentSearch.length) {
    items = items.filter(it => it.name.toLowerCase().includes(currentSearch.toLowerCase()))
  }

  items.sort((a, b) => {
    if (sort === "az") {
      return a.name > b.name ? 1 : -1
    } else if (sort === "latest") {
      return Number(a.objectID) < Number(b.objectID) ? 1 : -1
    } else {
      return Number(a.objectID) < Number(b.objectID) ? 1 : -1
    }
  })

  const currentData = items

  const [showCreateBoard, setShowCreateBoard] = useState(false)
  const [objectClass, setObjectClass] = useState()
  const [objectID, setObjectID] = useState()
  const [variantID, setVariantID] = useState(null)
  const showAddBoard = (className, objectID, vt) => {
    if (isLoggedIn()) {
      setObjectClass(className)
      setObjectID(objectID)
      if (vt) {
        setVariantID(vt.objectID)
      }
      setShowCreateBoard(true)
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = "/login/"
      }
    }
  }

  const [tiles, setTiles] = useState([])
  useEffect(() => {
    let renderTiles = []
    for (const [idx, item] of currentData.entries()) {
      let isSaved = false

      if (item.variantTiles && item.variantTiles.length) {
        for (const [index, vt] of item.variantTiles.entries()) {
          isSaved = false
          if (typeof currentBoards !== 'undefined') {
            for (const board of currentBoards) {
              const items = JSON.parse(board.Items)
              for (const key in items) {
                if (key.includes(item.className) && items[key].ID == item.objectID && items[key].VariantID == vt.objectID) {
                  isSaved = true
                }
              }
            }
          }
          if (activeFilters.length && !filterVariant(activeFilters, vt)) continue
          let ip = vt.rrPrice || 0
          ip = parseFloat(
            Math.round(ip * 1000) / 1000
          ).toFixed(2)
          let formattedPrice = "$" + ip.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          let vt_image = null
          if (vt.featureImagePath) {
            vt_image = vt.featureImagePath
          } else if (vt.productImagePath) {
            vt_image = vt.productImagePath
          }else if (vt.featureProductImagePath) {
            vt_image = vt.featureProductImagePath
          }
          var _id = item.objectID + vt.objectID
          vt.Image = vt_image
          renderTiles.push({
            objectID: item.objectID,
            id: _id,
            tilename: item.name,
            featuredRRPrice: vt.rrPrice,
            featured: vt.featured,
            item: { ...item },
            vt: { ...vt },
            tileImageSrc: vt.Image,
            formattedPrice,
            isSaved,
          })
        }
      } else {
        if (typeof currentBoards !== 'undefined') {
          for (const board of currentBoards) {
            const items = JSON.parse(board.Items)
            for (const key in items) {
              if (key.includes(item.className) && items[key].ID == item.objectID) {
                isSaved = true
              }
            }
          }
        }
        renderTiles.push({
          objectID: item.objectID,
          id: item.objectID,
          tilename: item.name,
          featuredRRPrice: item.featuredRRPrice,
          featured: false,
          item: { ...item },
          vt: null,
          tileImageSrc: item.Image,
          formattedPrice: item.formattedPrice,
          isSaved,
        })
      }
    }

    renderTiles.sort((i1, i2) => {
      if (i1.featured && !i2.featured) return -1
      if (!i1.featured && i2.featured) return 1
      return 0
    })

    renderTiles.sort((a, b) => {
      if (sort === "az") {
        return a.tilename > b.tilename ? 1 : -1
      } else if (sort === "latest") {
        return Number(a.objectID) < Number(b.objectID) ? 1 : -1
      } else if (sort === "pricehightolow") {
        return a.featuredRRPrice < b.featuredRRPrice ? 1 : -1
      } else if (sort === "pricelowtohigh") {
        return a.featuredRRPrice > b.featuredRRPrice ? 1 : -1
      } else {
        return Number(a.objectID) < Number(b.objectID) ? 1 : -1
      }
    })

    setTiles(renderTiles)
  }, [activeFilters, currentBoards, sort, currentSearch])

  const renderTileGrid = useMemo(() => {
    if (tiles.length == 0 || (tiles.length > 0 && tiles.length === tiles.slice(0, pageLimit).length)) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    return tiles.slice(0, pageLimit).map((tile, idx) => (
      <div key={idx} className={`grid-item ${tile.isSaved ? 'liked' : ''} ${tile.item.showTitleOnTile == 1 ? ' showtitle' : ''} ${tile.item.tileBlackTitle == 1 ? 'text-black' : ''} ${tile.item.objectID} ${tile.item.imagePath ? '' : ' no-image'}`}>
        <button
          type="button"
          className="btn-like"
          onClick={() => showAddBoard(tile.item.className, tile.item.objectID, tile.vt)}
        >
          {tile.isSaved ? (
            <>
              <svg className="clear-heart" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                <path id="Path_878" data-name="Path 878" d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
              </svg>
              <div className="clear-item"></div>
            </>
            ) :(
            <svg id="icon-heart" width="35" height="35" viewBox="0 0 35 35"><path d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"></path></svg>
            )}
        </button>
        <a onClick={e => setUpQuickViewModal(e, tile.item, tile.vt)} href={tile.item.link} className={`product-variant grid-item-inner ${tile.item.imagePosition}`}>
          {tile.tileImageSrc && (
            <img className="p-variant" src={tile.tileImageSrc} alt="" />
          )}
          <div className="grid-caption">
            <h4>{tile.tilename}</h4>
            {(tile.vt && tile.vt.name) && (
            <div className="grid-bottom">              
                <p className="variant-name">{tile.vt.name}</p>              
            </div>
            )}
          </div>
        </a>
      </div>
    ))
  }, [tiles, pageLimit])

  const [showQuickView, setShowQuickView] = useState(false)
  const [quickViewData, setQuickViewData] = useState(null)
  const setUpQuickViewModal = useCallback((e, item, vt) => {
    e.preventDefault()
    item.vt = vt
    setQuickViewData(item)
    setShowQuickView(true)
  }, [])

  const Member = getUser()
  let boards = []
  if (isLoggedIn()) {
    if (typeof Member.Boards !== 'undefined')
      boards = Object.keys(Member.Boards).map(key => Member.Boards[key])
  }

  return (
    <>
      <Masonry className="grid"
        options={{ columnWidth: '.grid-sizer', transitionDuration: 0, percentPosition: true }}
      >
        <div className="grid-sizer"></div>
        {renderTileGrid}
      </Masonry>
      {hasMore && (
        <div className="text-center">
          <button
            onClick={loadPage}
            className="btn btn-black btn-lg mt-5"
          >
            LOAD MORE
          </button>
        </div>
      )}
      <CreateBoardModal
        show={showCreateBoard}
        handleClose={() => setShowCreateBoard(false)}
        objectClass={objectClass}
        objectID={objectID}
        variantID={variantID}
        />
      {quickViewData !== null && (
        <QuickViewModalData
          show={showQuickView}
          handleClose={() => setShowQuickView(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
        />
      )}
    </>
  )
}
